import { Route, Routes } from "react-router-dom";
import './App.css';
import { Home } from "./pages/Home"
import { Kontakt } from "./pages/Kontakt"
import { Datenschutz } from "./pages/Datenschutz"

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home/>}/>
        <Route path="/kontakt" element={ <Kontakt/>}/>
        <Route path="/datenschutz" element={ <Datenschutz/>}/>
      </Routes>
    </div>
  );
}

export default App;
